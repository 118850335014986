

























































import Vue, { PropType } from 'vue';
import Icon from '@/components/ui/Icon.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import debounce from 'lodash/debounce';
import { ContentOption } from '@/store/models';
import Badge from '@/components/ui/Badge.vue';
import { ContentType } from '@/store/enums';
import Spinner from '@/components/ui/Spinner.vue';

export default Vue.extend({
    name: 'ContentMultiSelect',
    components: {
        VueTagsInput,
        Badge,
        Icon,
        Spinner,
    },
    props: {
        value: {
            type: Array as PropType<ContentOption[]>,
            default: () => [],
        },
        searchResults: {
            type: Array as PropType<ContentOption[]>,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: undefined,
        },
        id: {
            type: String,
            default: undefined,
        },
        label: {
            type: String,
            default: undefined,
        },
        hideLabel: {
            type: Boolean,
            default: true,
        },
    },
    data(): { tag: string; isFocused: boolean } {
        return {
            tag: '',
            isFocused: false,
        };
    },
    computed: {
        autocompleteItems(): {
            text?: string;
            type: string;
            index: number;
            identifier: string;
            info?: string;
        }[] {
            return this.searchResults.map((result, index) => ({
                text: result?.text || '---',
                type: result.type,
                identifier: result?.id?.toString() || result.code!,
                info: result.info || '',
                index,
            }));
        },
        tags(): { text: string }[] {
            return this.selectedContent.map((item) => ({ text: item.text }));
        },
        selectedContent: {
            get(): ContentOption[] {
                return this.value;
            },
            set(value: ContentOption[]) {
                this.$emit('input', value);
            },
        },
    },
    watch: {
        tag(query: string) {
            this.getContent(query);
        },
    },
    methods: {
        getContent: debounce(function (this: any, query: string) {
            if (query.length === 0) {
                return;
            }
            this.$emit('search', query);
        }, 400),
        onAdd({ tag, addTag }: { tag: any; addTag: () => void }) {
            const searchResult: ContentOption = !tag.type
                ? new ContentOption({
                      text: tag.text,
                      type: ContentType.Any,
                  })
                : this.searchResults[tag.index];
            addTag();
            this.selectedContent = [...this.selectedContent, searchResult];
            this.tag = '';
        },
        onDelete({ tag, index, deleteTag }: { tag: any; index: number; deleteTag: () => void }) {
            const copy = [...this.selectedContent];
            copy.splice(index, 1);
            this.selectedContent = copy;
            deleteTag();
        },
        onClear(): void {
            this.selectedContent = [];
        },
    },
});
