import { CommentSortingOptions } from '@/store/enums';
export default [
    { value: 'Date (Newest)', id: CommentSortingOptions.dateNewest },
    { value: 'Date (Oldest)', id: CommentSortingOptions.dateOldest },
    { value: 'Most Votes', id: CommentSortingOptions.votes },
    { value: 'Most Reports', id: CommentSortingOptions.reports },
    { value: 'Status', id: CommentSortingOptions.status },
    { value: 'Score (highest)', id: CommentSortingOptions.higherConfidence },
    { value: 'Score (lowest)', id: CommentSortingOptions.lowerConfidence },
];
