<template>
    <div class="app-form">
        <div
            v-for="input in schema"
            :key="input.id || input.name"
            :style="{ 'grid-column': `span ${input.colSpan || 2}` }"
        >
            <ContentMultiSelect
                v-if="input.type === $options.InputType.multiSelect"
                v-model="model[input.name]"
                v-bind="setInputAttr(input)"
                :search-results="queryResults[input.name] || []"
                @search="onQuery(input, input.search, $event)"
            />
            <Datepicker
                v-else-if="input.type === $options.InputType.date"
                v-model="model[input.name]"
                v-bind="setInputAttr(input)"
                :min="model[input.minInputRef] || input.min"
            />
            <Input
                v-else
                v-model="model[input.name]"
                v-bind="setInputAttr(input)"
                :debounce-delay="400"
            />
        </div>
    </div>
</template>

<script>
import { InputType } from '@/enums';
import ContentMultiSelect from '@/components/ContentMultiSelect.vue';
import { Input, Datepicker } from '@/components/forms';
export default {
    name: 'AppForm',
    components: {
        Datepicker,
        Input,
        ContentMultiSelect,
    },
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        schema: {
            type: Array,
            required: true,
            validator(schema) {
                return schema.every((input) => {
                    return typeof input === 'object' && input.name;
                });
            },
        },
    },
    data() {
        return {
            model: this.value,
            queryResults: {},
        };
    },
    watch: {
        value: {
            immediate: true,
            deep: true,
            handler() {
                this.model = this.value;
                this.onInput();
            },
        },
    },
    created() {
        this.$options.InputType = InputType;
    },
    methods: {
        setInputAttr(input) {
            return {
                id: input.name || input.id,
                name: input.name,
                placeholder: input.placeholder,
                label: input.label,
                hasMarginBottom: false,
                type: input.type || this.$options.InputType.text,
                showClearIcon: input.showClearIcon,
            };
        },
        onInput() {
            this.$emit('input', this.model);
        },
        async onQuery({ name }, searchFunction, query) {
            this.queryResults = { ...this.queryResults, [name]: await searchFunction(query) };
        },
    },
};
</script>

<style lang="scss" scoped>
.app-form {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    margin-bottom: $form-group-margin-bottom;
}
</style>
