<template>
    <CellContext v-bind="$attrs" v-on="$listeners">
        <template v-slot:default="item">
            <span :title="item.title"> {{ item.value }}&nbsp;&nbsp;{{ item.label }} </span>
        </template>
    </CellContext>
</template>

<script>
import CellContext from './CellContext';
export default {
    name: 'CellScore',
    components: { CellContext },
};
</script>
