

























































































































import Vue from 'vue';
import { UserComment } from '@/store/models';
import { LoadingFlag } from '@/store/enums';
import {
    ContentType,
    ContentItemType,
    RetrainClassification,
    CommentsRatingStatusReason,
    CommentsRatingStatus,
    ToxicityType,
    ClassificationMode,
} from '@inconvo/types/enums';
import { Select, Toggle } from '@/components/forms';
import Icon from '@/components/ui/Icon.vue';
import Button from '@/components/ui/Button.vue';
import { format } from 'date-fns';
import { capitaliseString } from '@/utils/capitalize';
interface DescriptionSpec {
    id: number;
    label: string;
    value: string | number;
}

interface CommentItem extends UserComment {
    hasDetails: boolean;
    hasChanges: boolean;
}
type KeyOfToxicityType = keyof typeof ToxicityType;
export default Vue.extend({
    name: 'UserCommentsDetails',
    components: {
        Icon,
        Button,
        Select,
        Toggle,
    },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
    computed: {
        descriptionSpecs(): DescriptionSpec[] {
            const score = this.item.toxicity?.overallScore ?? this.item.confidence ?? 0;
            const scoreTitle = this.item.toxicity?.overallScore ? 'Overall Score' : 'Score';
            return [
                { id: 1, label: 'Convo', value: this.item.convo },
                { id: 2, label: 'Message', value: this.item.contentItem },
                { id: 3, label: 'Comment', value: this.item.comment },
                {
                    id: 4,
                    label: 'Classification mode',
                    value: this.capitaliseString(this.item.classificationMode),
                },
                { id: 5, label: scoreTitle, value: score },
            ];
        },
        toxicityLabels(): { label: string; score: number; id: KeyOfToxicityType }[] {
            if (!this.item.toxicity?.labels) {
                return [];
            }
            return Object.entries<number>(this.item.toxicity.labels).map(([key, value]) => {
                const id = key as KeyOfToxicityType;
                const toxicityString = ToxicityType[id];
                return {
                    id,
                    label: this.capitaliseString(toxicityString),
                    score: value,
                };
            });
        },
    },
    created() {
        const options = this.$options as any;
        options.statusReasonOptions = Object.entries(CommentsRatingStatusReason).map(
            ([id, value]) => ({
                id,
                value: this.capitaliseString(value),
                disabledText: 'Not available',
            }),
        );
        options.statusOptions = Object.entries(CommentsRatingStatus).map(([id, value]) => ({
            id,
            value: this.capitaliseString(value),
        }));
        options.retrainClassificationOptions = Object.entries(RetrainClassification).map(
            ([id, value]) => ({
                id,
                value: this.capitaliseString(value),
                disabledText: 'Not available',
            }),
        );
        options.LoadingFlag = LoadingFlag;
        options.ContentItemType = ContentItemType;
        options.ContentType = ContentType;
    },
    methods: {
        capitaliseString,
        toxicityLabel(toxicity: KeyOfToxicityType) {
            const toxicityString = ToxicityType[toxicity];
            return this.capitaliseString(toxicityString);
        },
        isHighestToxicity(toxicity: KeyOfToxicityType) {
            return this.item.toxicity.highestScore.label === toxicity;
        },
        isDisabledToxicity(toxicity: KeyOfToxicityType) {
            return [ToxicityType.insult, ToxicityType.profanity].includes(ToxicityType[toxicity]);
        },
        generateId(name: string, id: string): string {
            return `${name}-${id}`;
        },
        filterStatusReasonOptions(status: CommentsRatingStatus) {
            let allowedStatusReason: CommentsRatingStatusReason[] = [];
            const options = this.$options as any;

            switch (status) {
                case CommentsRatingStatus.flagged:
                    allowedStatusReason = [CommentsRatingStatusReason.blacklisted];
                    break;
                case CommentsRatingStatus.safe:
                    allowedStatusReason = [CommentsRatingStatusReason.whitelisted];
                    break;
            }

            return options.statusReasonOptions.map((item: { id: CommentsRatingStatusReason }) => ({
                ...item,
                disabled: !allowedStatusReason.includes(item.id),
            }));
        },
        filterRetrainClassificationOptions(status: CommentsRatingStatus) {
            let allowedItems: any[] = [];
            const options = this.$options as any;

            switch (status) {
                case CommentsRatingStatus.flagged:
                    allowedItems = [
                        RetrainClassification.profanity,
                        RetrainClassification.offensive,
                    ];
                    break;
                case CommentsRatingStatus.safe:
                    allowedItems = [RetrainClassification.neutral];
                    break;
            }

            return options.retrainClassificationOptions.map((item: { id: string }) => ({
                ...item,
                disabled: !allowedItems.includes(item.id),
            }));
        },
        retrainToggleIsDisabled(status: CommentsRatingStatus) {
            return status === CommentsRatingStatus.unclassified;
        },
        timeText(time: Date) {
            return `${format(time, 'dd/MM/yyyy')} at ${format(time, 'HH:mm')}`;
        },
        onChange(item: CommentItem, key: string, value: any): void {
            this.$emit('change', { item, key, value });
        },
        onStatusChange(item: CommentItem, value: string) {
            this.onChange(item, 'status', value);

            switch (value) {
                case CommentsRatingStatus.flagged:
                    this.onChange(item, 'statusReason', CommentsRatingStatusReason.blacklisted);
                    break;
                case CommentsRatingStatus.safe:
                    this.onChange(item, 'statusReason', CommentsRatingStatusReason.whitelisted);
                    break;
                default:
                    this.onChange(item, 'statusReason', null);
            }
        },
    },
});
