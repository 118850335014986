







































































































































import AppForm from '@/components/forms/AppForm.vue';
import TextButton from '@/components/ui/TextButton.vue';
import Datatable from '@/components/Datatable.vue';
import { InlineSelect, Select } from '@/components/forms';
import Paging from '@/components/Paging.vue';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner.vue';
import Icon from '@/components/ui/Icon.vue';
import IconButton from '@/components/ui/IconButton.vue';
import PageHeading from '@/components/ui/PageHeading.vue';
import sortOrderOptions from '@/json/commentsSortOrderOptions';
import statusSelections from '@/json/commentsStatusSelections';
import config from '@/config';
import {
    BadgeType,
    CommentsReportType,
    CommentSortingOptions,
    ContentItemType,
    ContentType,
    ConvoTypes,
    LoadingFlag,
} from '@/store/enums';
import { RetrainClassification, ToxicityType } from '@inconvo/types/enums';
import { CommentToxicity } from '@inconvo/types/interfaces';
import { ContentOption, UserComment } from '@/store/models';
import { Breadcrumb } from '@/store/models/breadcrumb.dto';
import service, { findContentOption } from '@/store/services/contentService';
import { capitaliseString } from '@/utils/capitalize';
import { CommentsRatingStatus, CommentsRatingStatusReason } from '@/store/enums';
import { InputType } from '@/enums';
import { format, sub } from 'date-fns';
import Vue from 'vue';
import { Dictionary } from 'vue-router/types/router';
import { mapActions, mapState } from 'vuex';
import CellScore from '@/components/DataTable/CellScore.vue';
import CellContext from '@/components/DataTable/CellContext.vue';
import UserCommentsDetails from '@/components/UserCommentsDetails.vue';

interface CommentItem extends UserComment {
    hasDetails: boolean;
    hasChanges: boolean;
}

interface CommentStatusSelection extends Partial<UserComment> {
    label: string;
    icon: string;
}
interface ICommentsFilter {
    target: ContentOption[];
    sort?: CommentSortingOptions;
    userIdentifier?: string;
    fromDate?: Date;
    toDate?: Date;
}
interface TableFilters {
    [id: string]: {
        selected: string | number;
        items: { title: string; value: string }[];
    };
}

const QUERY_PARAM_MAP: Partial<Record<ContentType | ContentItemType, string>> = {
    [ContentType.Channel]: 'channel_codes',
    [ContentType.Convo]: 'convo_ids',
    [ContentItemType.TextInput]: 'text_input_item_ids',
    [ContentItemType.CommentsRating]: 'comments_rating_item_ids',
    [ContentType.Any]: 'comment_text',
};

let latestSearchTime: Date;

const onContentSearch = async (query: string) => {
    let searchResults: any[] = [];
    const contentSearchTime = new Date();
    latestSearchTime = contentSearchTime;
    const results = await service.searchContent({
        query,
        contentTypes: [ContentItemType.TextInput, ContentItemType.CommentsRating],
        convoTypes: [ConvoTypes.Scripted],
    });

    if (contentSearchTime === latestSearchTime) {
        searchResults = results.map((result: any) => findContentOption(result));
    }
    return searchResults;
};

const initialFilterValues: ICommentsFilter = {
    target: [],
};

export default Vue.extend({
    name: 'UserComments',
    components: {
        InlineSelect,
        FullPageLoadingSpinner,
        PageHeading,
        Select,
        Datatable,
        CellScore,
        CellContext,
        Paging,
        IconButton,
        Icon,
        AppForm,
        TextButton,
        UserCommentsDetails,
    },
    data(): {
        breadcrumbs: Breadcrumb[];
        showContentSelector: boolean;
        formValues: ICommentsFilter;
        formSchema: any[];
        detailsOpenedIds: string[];
        innerItems: any[];
        selectedStatsFilterOption: string | null;
        quickStatusSelections: CommentStatusSelection[];
        page: Number;
        filterOptions: {
            toxicity?: string;
            status?: CommentsRatingStatus;
            statusReason?: CommentsRatingStatusReason;
            reportType?: CommentsReportType;
            score?: string;
        };
    } {
        return {
            page: 1,
            formValues: { ...initialFilterValues },
            filterOptions: {
                toxicity: (this.$route.query.toxicity as string) || undefined,
                status: (this.$route.query.status as CommentsRatingStatus) || undefined,
                statusReason:
                    (this.$route.query.statusReason as CommentsRatingStatusReason) || undefined,
                reportType: (this.$route.query.reportType as CommentsReportType) || undefined,
                score: (this.$route.query.score as string) || undefined,
            },
            formSchema: [
                {
                    type: InputType.multiSelect,
                    name: 'target',
                    search: onContentSearch,
                    placeholder: 'Search for convo, channel or comment',
                    label: 'Content',
                    colSpan: 5,
                },
                {
                    name: 'userIdentifier',
                    label: 'User',
                    placeholder: 'Search for user email, pmx, person or chat user ID',
                    colSpan: 3,
                    showClearIcon: true,
                },
                {
                    name: 'fromDate',
                    type: InputType.date,
                    label: 'From',
                    placeholder: 'Search after',
                    showClearIcon: true,
                },
                {
                    name: 'toDate',
                    type: InputType.date,
                    label: 'To',
                    minInputRef: 'fromDate',
                    placeholder: 'Search before',
                    showClearIcon: true,
                },
            ],
            breadcrumbs: [],
            showContentSelector: false,
            detailsOpenedIds: [],
            innerItems: [],
            selectedStatsFilterOption: null,
            quickStatusSelections: statusSelections,
        };
    },
    computed: {
        ...mapState('userComments', ['comments', 'stats']),
        commentsItems(): CommentItem[] {
            return this.innerItems.map((item: UserComment) => ({
                ...item,
                hasDetails: this.detailsOpenedIds.indexOf(item.id) >= 0,
                hasChanges: this.itemHasChanges(item),
                ratingTotal: item.rating?.total || 0,
                reportTotal: item.reports?.total.toString() || '0',
            }));
        },
        tableFilters(): TableFilters {
            const confidenceScore = config.comments.confidenceScoreThreshold;
            return {
                status: {
                    selected: this.filterOptions.status || -1,
                    items: Object.entries(CommentsRatingStatus).map(([id, value]) => ({
                        title: this.capitaliseString(value),
                        value,
                    })),
                },
                score: {
                    selected: this.filterOptions.score || -1,
                    items: [
                        { title: `>= ${confidenceScore}%`, value: `>=${confidenceScore}` },
                        { title: `< ${confidenceScore}%`, value: `<${confidenceScore}` },
                    ],
                },
                statusReason: {
                    selected: this.filterOptions.statusReason || -1,
                    items: Object.entries(CommentsRatingStatusReason).map(([id, value]) => ({
                        title: this.capitaliseString(value),
                        value,
                    })),
                },
                reports: {
                    selected: this.filterOptions.reportType || -1,
                    items: Object.entries(CommentsReportType).map(([id, value]) => ({
                        title: this.capitaliseString(id),
                        value: id,
                        helpText: value,
                    })),
                },
                toxicity: {
                    selected: this.filterOptions.toxicity || -1,
                    items: Object.entries(ToxicityType).map(([id, value]) => ({
                        title: this.capitaliseString(value),
                        value: value,
                    })),
                },
            };
        },
        commentsInfo(): string {
            if (!this.stats) {
                return '';
            }
            const totalRetrainComments: number =
                Math.min(this.stats.retrain.neutral, 200) +
                Math.min(this.stats.retrain.offensive, 200) +
                Math.min(this.stats.retrain.profanity, 200);

            let retrainText =
                `<br><span class="comments-stats-retrain-info-success">The retraining` +
                `service will use ${totalRetrainComments} comments in the next run.</span>`;

            if (
                this.stats.retrain.neutral < 50 ||
                this.stats.retrain.offensive < 50 ||
                this.stats.retrain.profanity < 50
            ) {
                retrainText =
                    '<br><span class="comments-stats-retrain-info-error">Currently,' +
                    'there are not enough comments in one or more classes.</span>';
            }

            return (
                `<div style='line-height:2em'>The retraining process will run automatically every day.` +
                `Notice that:<br>` +
                `<li>It only starts if there's a minimum of 50 comments per class ` +
                `(Neutral, Offensive and Profanity).</li>` +
                `<li>It will only pick a maximum of 200 comments per class</li>` +
                `Once finished, it will untoggle the retrain option for the comments it used in the process.` +
                `${retrainText}</div>`
            );
        },
    },
    watch: {
        comments: {
            immediate: true,
            deep: true,
            handler(comments): void {
                this.innerItems = comments.items;
            },
        },
    },
    async created() {
        this.setBreadcrumbs();
        this.setTemplateOptions();
        this.loadQueryParamData();
        this.getUserCommentsStatsAction(this.selectedStatsFilterOption);
    },
    methods: {
        ...mapActions('userComments', {
            getUserCommentsAction: 'getUserComments',
            getUserCommentsStatsAction: 'getUserCommentsStats',
            updateUserCommentAction: 'updateUserComment',
        }),
        capitaliseString,
        onUpdateSearch(params?: ICommentsFilter) {
            this.updateUrl(params);
            this.getUserComments();
        },
        toxicityLabel(toxicity: CommentToxicity) {
            const toxicityString = ToxicityType[toxicity?.highestScore?.label];
            return this.capitaliseString(toxicityString);
        },
        getCellScoreItems(
            items: { [Key: string]: number },
            titles: { [Key: string]: string } = {},
        ) {
            return Object.entries(items).map(([id, value]) => ({
                id,
                value,
                label: this.capitaliseString(id),
                title: titles[id],
            }));
        },
        setTemplateOptions() {
            const options = this.$options as any;
            options.statsFilterOptions = [
                {
                    value: 'Last 2 weeks',
                    id: format(sub(new Date(), { weeks: 2 }), 'yyyy-MM-dd'),
                },
                {
                    value: 'Last month',
                    id: format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd'),
                },
                {
                    value: 'Last 3 months',
                    id: format(sub(new Date(), { months: 3 }), 'yyyy-MM-dd'),
                },
                {
                    value: 'All time',
                    id: null,
                },
            ];
            options.LoadingFlag = LoadingFlag;
            options.ContentItemType = ContentItemType;
            options.ContentType = ContentType;
            options.columns = [
                {
                    value: 'status',
                    header: 'Status',
                    type: 'slot',
                    width: '10%',
                    class: 'table-row-actions',
                },
                {
                    value: 'statusReason',
                    header: 'Reason',
                    type: 'slot',
                    width: '10%',
                    class: 'table-row-actions',
                },
                {
                    value: 'toxicity',
                    header: 'Toxicity',
                    type: 'slot',
                    width: '10%',
                },
                {
                    value: 'score',
                    header: 'Score',
                    type: 'slot',
                    width: '80px',
                },
                {
                    value: 'reports',
                    header: 'Reports',
                    type: 'slot',
                    width: '80px',
                    class: 'table-row-actions',
                },
                {
                    value: 'comment',
                    header: 'Comment',
                    type: 'text',
                    width: 'auto',
                },
                {
                    value: 'ratingTotal',
                    header: 'Ratings',
                    type: 'text',
                    width: '80px',
                },
                {
                    value: 'action',
                    header: '',
                    type: 'slot',
                    width: '5%',
                },
            ];
            options.statusOptions = Object.entries(CommentsRatingStatus).map(([id, value]) => ({
                id,
                value,
            }));
            options.statusReasonOptions = Object.entries(CommentsRatingStatusReason).map(
                ([id, value]) => ({
                    id,
                    value: this.capitaliseString(value),
                    disabledText: 'Not available',
                }),
            );
            options.retrainClassificationOptions = Object.entries(RetrainClassification).map(
                ([id, value]) => ({
                    id,
                    value: this.capitaliseString(value),
                    disabledText: 'Not available',
                }),
            );

            options.sortOrderOptions = sortOrderOptions;
            options.commentsReportType = CommentsReportType;
        },
        setBreadcrumbs() {
            this.breadcrumbs = [new Breadcrumb('User comments', { name: 'user-comments' })];
        },
        onClickRow(event: Event | null, item: CommentItem) {
            const idx = this.detailsOpenedIds.indexOf(item.id);

            if (idx >= 0) {
                this.detailsOpenedIds.splice(idx, 1);
            } else {
                this.detailsOpenedIds.push(item.id);
            }
        },
        generateId(name: string, id: string): string {
            return `${name}-${id}`;
        },
        timeText(time: Date) {
            return `${format(time, 'dd/MM/yyyy')} at ${format(time, 'HH:mm')}`;
        },
        getBadgeType(status: string) {
            switch (status) {
                case CommentsRatingStatus.unclassified:
                    return BadgeType.Neutral;
                case CommentsRatingStatus.flagged:
                    return BadgeType.Error;
                case CommentsRatingStatus.safe:
                    return BadgeType.Success;
                default:
                    return BadgeType.Neutral;
            }
        },
        onChange(item: CommentItem, property: string, value: string | null) {
            const items = [...this.innerItems];
            const idx = items.findIndex((o) => o.id === item.id);

            if (idx >= 0) {
                const comment = { ...items[idx] };
                comment[property] = value;
                items[idx] = comment;
                this.innerItems = items;
            }
        },
        onStatusChange(item: CommentItem, value: string) {
            this.onChange(item, 'status', value);

            switch (value) {
                case CommentsRatingStatus.flagged:
                    this.onChange(item, 'statusReason', CommentsRatingStatusReason.blacklisted);
                    break;
                case CommentsRatingStatus.safe:
                    this.onChange(item, 'statusReason', CommentsRatingStatusReason.whitelisted);
                    break;
                default:
                    this.onChange(item, 'statusReason', null);
            }
        },
        selectPage(_: Event, page: number) {
            this.page = page;
            this.onUpdateSearch();
        },
        filterItems(value: {
            column: string;
            item:
                | CommentsRatingStatus
                | CommentsReportType
                | CommentsRatingStatusReason
                | string
                | number;
        }) {
            const itemValue = value.item === -1 ? undefined : `${value.item}`;

            if (value.column === 'status') {
                this.filterOptions.status = itemValue as CommentsRatingStatus;
            }
            if (value.column === 'statusReason') {
                this.filterOptions.statusReason = itemValue as CommentsRatingStatusReason;
            }
            if (value.column === 'reports') {
                this.filterOptions.reportType = itemValue as CommentsReportType;
            }

            if (value.column === 'score') {
                this.filterOptions.score = itemValue;
            }
            if (value.column === 'toxicity') {
                this.filterOptions.toxicity = itemValue;
            }

            this.onUpdateSearch();
        },
        getUserComments(): void {
            const query = this.getQueryParams();
            this.getUserCommentsAction(query);
        },
        retrainToggleIsDisabled(status: CommentsRatingStatus) {
            return status === CommentsRatingStatus.unclassified;
        },
        updateUserComment(item: CommentItem) {
            this.updateUserCommentAction(item);
            this.onClickRow(null, item);
        },
        onDetailsChange({ item, key, value }: { item: CommentItem; key: string; value: any }) {
            this.onChange(item, key, value);
        },
        itemHasChanges(item: Record<string, any>) {
            const observedProperties = ['status', 'statusReason', 'retrain'];
            const originalItem = this.comments?.items.find((o: UserComment) => o.id === item.id);
            return !observedProperties.every((propery) => item[propery] === originalItem[propery]);
        },

        updateUrl(params?: ICommentsFilter) {
            const query = this.buildQueryParams(params);
            const route = this.$router.resolve({ path: this.$router.currentRoute.path, query });
            if (this.$route.fullPath !== route.resolved.fullPath) {
                this.$router.replace({ path: this.$router.currentRoute.path, query });
            }
        },
        async loadQueryParamData() {
            const query = this.$route.query;

            if (query && Object.keys(query).length) {
                this.addContentItemQueryParam(query, 'text_input_item_ids[]');
                this.addContentItemQueryParam(query, 'comments_rating_item_ids[]');

                const result = await service.getContentBatch(query);
                const commentTags = this.getCommentTextsInQuery(query);

                this.formValues = {
                    userIdentifier: (query.userIdentifier as string) || undefined,
                    fromDate: query.fromDate ? new Date(query.fromDate as string) : undefined,
                    toDate: query.toDate ? new Date(query.toDate as string) : undefined,
                    target: [...result, ...commentTags],
                };
            }
        },
        addContentItemQueryParam(query: Dictionary<string | (string | null)[]>, param: string) {
            if (!query.content_item_ids) {
                query.content_item_ids = [];
            }
            const paramValue = query[param];
            if (paramValue) {
                query.content_item_ids =
                    paramValue instanceof Array
                        ? [...query.content_item_ids, ...paramValue]
                        : [...query.content_item_ids, paramValue];
            }
        },
        getCommentTextsInQuery(query: Dictionary<string | (string | null)[]>) {
            let commentTexts: ContentOption[] = [];
            if (query['comment_text[]']) {
                const commentTextArray = Array.isArray(query['comment_text[]'])
                    ? query['comment_text[]']
                    : [query['comment_text[]']];
                commentTexts = commentTextArray.reduce((prev: ContentOption[], text) => {
                    if (!text) {
                        return prev;
                    }
                    const commentText = new ContentOption({ text, type: ContentType.Any });
                    return [...prev, commentText];
                }, []);
            }
            return commentTexts;
        },
        buildQueryParams(params?: ICommentsFilter): Record<string, string | string[]> {
            const { target, ...queryParams } = params || this.formValues;
            let query = target.reduce((prev: any, cur: ContentOption) => {
                const type = cur.type === ContentType.Message ? cur.contentItemType : cur.type;
                const key = type ? `${QUERY_PARAM_MAP[type]}[]` : '';
                if (!key) {
                    return prev;
                }
                const identifier = cur?.id?.toString() || cur.code! || cur.text;
                const list = prev[key] ? [...prev[key], identifier] : [identifier];
                prev[key] = list;
                return prev;
            }, queryParams || ({} as Record<string, string | string[]>));

            query = { ...query, ...this.filterOptions };

            if (queryParams.sort) {
                query = { ...query, sort: queryParams.sort };
            }
            if (queryParams.userIdentifier) {
                query = { ...query, userIdentifier: queryParams.userIdentifier };
            }
            if (queryParams.fromDate) {
                query = { ...query, fromDate: queryParams.fromDate.toISOString() };
            }

            if (queryParams.toDate) {
                query = { ...query, toDate: queryParams.toDate.toISOString() };
            }

            if (+this.$route.query.page === this.page) {
                this.page = 1;
            }
            query = { ...query, page: this.page };

            return query;
        },
        getQueryParams() {
            const { target, ...queryParams } = this.formValues;

            const query = target.reduce<any>((prev: any, cur: ContentOption) => {
                if (
                    cur.type === ContentType.Message &&
                    cur.contentItemType === ContentItemType.TextInput &&
                    cur.id
                ) {
                    prev.textInputItemIds = [...(prev.textInputItemIds || []), cur.id.toString()];
                }
                if (
                    cur.type === ContentType.Message &&
                    cur.contentItemType === ContentItemType.CommentsRating &&
                    cur.id
                ) {
                    prev.commentsRatingItemIds = [
                        ...(prev.commentsRatingItemIds || []),
                        cur.id.toString(),
                    ];
                }
                if (cur.type === ContentType.Convo && cur.id) {
                    prev.convoIds = [...(prev.convoIds || []), cur.id.toString()];
                }
                if (cur.type === ContentType.Channel && cur.code) {
                    prev.channelCodes = [...(prev.channelCodes || []), cur.code];
                }
                if (cur.type === ContentType.Any && cur.text) {
                    prev.commentsText = [...(prev.commentsText || []), cur.text];
                }
                return prev;
            }, {});

            Object.entries(this.filterOptions).forEach(([key, value]) => {
                if (value) {
                    query[key] = value;
                }
            });

            if (queryParams.sort) {
                query.sort = queryParams.sort;
            }
            if (queryParams.userIdentifier) {
                query.userIdentifier = queryParams.userIdentifier;
            }
            if (queryParams.fromDate) {
                query.fromDate = queryParams.fromDate;
            }

            if (queryParams.toDate) {
                query.toDate = queryParams.toDate;
            }

            query.page = this.page;

            return query;
        },
        onStatsFilterChange(value: CommentSortingOptions) {
            this.selectedStatsFilterOption = value;

            this.getUserCommentsStatsAction(this.selectedStatsFilterOption);
        },
        resetFilters() {
            this.filterOptions = {};
            this.formValues = { ...initialFilterValues };
        },
    },
});
