import {
    CommentsRatingStatus,
    CommentsRatingStatusReason,
    RetrainClassification,
} from '@inconvo/types/enums';

export default [
    {
        label: 'Approve Comment',
        icon: 'checkmark-ring',
        status: CommentsRatingStatus.safe,
        statusReason: CommentsRatingStatusReason.whitelisted,
        retrain: true,
        retrainClassification: RetrainClassification.neutral,
    },
    {
        label: 'Reject Comment (Profanity)',
        icon: 'cross',
        status: CommentsRatingStatus.flagged,
        statusReason: CommentsRatingStatusReason.blacklisted,
        retrain: true,
        retrainClassification: RetrainClassification.profanity,
    },
    {
        label: 'Reject Comment (Offensive)',
        icon: 'cross',
        status: CommentsRatingStatus.flagged,
        statusReason: CommentsRatingStatusReason.blacklisted,
        retrain: true,
        retrainClassification: RetrainClassification.offensive,
    },
];
