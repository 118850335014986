<template>
    <Menu v-if="items.length" :button-text="`${label}`" :badge-type="badgeType">
        <template>
            <DropDownItem
                v-for="item in items"
                :key="item.id"
                :icon="item.icon"
                @click="$emit('click', item)"
            >
                <slot v-bind="item">
                    {{ item.label }}
                </slot>
            </DropDownItem>
        </template>
    </Menu>
    <div v-else class="cell-context">
        <Badge v-if="badgeType" :type="badgeType"> {{ label }} </Badge>
        <template v-else>{{ label }}</template>
    </div>
</template>

<script>
import Menu from '@/components/ui/Menu.vue';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
export default {
    name: 'CellContext',
    components: { Menu, DropDownItem },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        label: {
            type: [Number, String],
            default: '',
        },
        badgeType: {
            type: String,
            default: undefined,
        },
    },
};
</script>

<style lang="scss" scoped>
.cell-context {
    padding: 10px 16px;
}
</style>
