var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-comments"},[_c('FullPageLoadingSpinner',{attrs:{"show":_vm.$wait.is(_vm.$options.LoadingFlag.UserCommentsGet)}}),_c('PageHeading',{attrs:{"breadcrumbs":_vm.breadcrumbs,"title":"User comments"}}),_c('AppForm',{attrs:{"schema":_vm.formSchema},on:{"input":_vm.onUpdateSearch},model:{value:(_vm.formValues),callback:function ($$v) {_vm.formValues=$$v},expression:"formValues"}}),_c('div',{staticClass:"flex m-b-1"},[_c('div',{staticClass:"flex stats"},[_c('div',{staticClass:"p-r-1 stats-label"},[_vm._v(" Stats "),_c('InlineSelect',{staticClass:"stats-date-filter",attrs:{"value":_vm.selectedStatsFilterOption,"options":_vm.$options.statsFilterOptions},on:{"input":_vm.onStatsFilterChange}}),_vm._v(": ")],1),_c('div',[_c('strong',[_vm._v("Total comments:")]),_vm._v(" "+_vm._s(_vm.stats.total.toLocaleString()))]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"stats-separator"},[_vm._v("|")]),_c('div',{staticClass:"stats-section-label"},[_vm._v("Classification")]),_c('div',{staticClass:"p-r-1"},[_c('em',[_vm._v("Safe:")]),_vm._v(" "+_vm._s(_vm.stats.status.safe.toLocaleString()))]),_c('div',{staticClass:"p-r-1"},[_c('em',[_vm._v("Flagged:")]),_vm._v(" "+_vm._s(_vm.stats.status.flagged.toLocaleString())+" ")]),_c('div',{staticClass:"p-r-1"},[_c('em',[_vm._v("Unclassified:")]),_vm._v(" "+_vm._s(_vm.stats.status.unclassified.toLocaleString())+" ")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"stats-separator"},[_vm._v("|")]),_c('div',{staticClass:"stats-section-label"},[_vm._v(" Retrain "),_c('Icon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                            content: _vm.commentsInfo,
                            classes: 'comments-stats-retrain-info',
                        }),expression:"{\n                            content: commentsInfo,\n                            classes: 'comments-stats-retrain-info',\n                        }"}],staticClass:"stats-icon-info-icon",attrs:{"name":"icon-info"}})],1),_c('div',{staticClass:"p-r-1"},[_c('em',[_vm._v("Neutral:")]),_vm._v(" "+_vm._s(_vm.stats.retrain.neutral.toLocaleString())+" ")]),_c('div',{staticClass:"p-r-1"},[_c('em',[_vm._v("Offensive:")]),_vm._v(" "+_vm._s(_vm.stats.retrain.offensive.toLocaleString())+" ")]),_c('div',[_c('em',[_vm._v("Profanity:")]),_vm._v(" "+_vm._s(_vm.stats.retrain.profanity.toLocaleString()))])])])]),_c('div',{staticClass:"align-right p-t-1"},[_c('Select',{staticClass:"sort-by",attrs:{"label":"Sort by","options":_vm.$options.sortOrderOptions,"has-margin-bottom":false,"placeholder":"Sort by..."},model:{value:(_vm.formValues.sort),callback:function ($$v) {_vm.$set(_vm.formValues, "sort", $$v)},expression:"formValues.sort"}})],1),_c('div',{staticClass:"align-right m-t-1"},[(_vm.formValues.target.length)?_c('TextButton',{on:{"click":_vm.getUserComments}},[_vm._v("Reload")]):_vm._e(),_c('TextButton',{on:{"click":_vm.resetFilters}},[_vm._v("Reset")])],1),_c('Datatable',{staticClass:"m-t-2",attrs:{"data":{ items: _vm.commentsItems },"columns":_vm.$options.columns,"filters":_vm.tableFilters},on:{"filterClick":_vm.filterItems},scopedSlots:_vm._u([{key:"action",fn:function(slotProps){return [_c('div',{staticClass:"align-right"},[_c('IconButton',{staticClass:"table-row-toggle",class:{ 'table-row-toggle--open': slotProps.item.hasDetails },attrs:{"icon":"arrow-down"},on:{"click":function($event){return _vm.onClickRow($event, slotProps.item)}}})],1)]}},{key:"statusReason",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.capitaliseString(item.statusReason))+" ")]}},{key:"status",fn:function(ref){
                        var item = ref.item;
return [_c('CellContext',{attrs:{"label":item.status,"badge-type":_vm.getBadgeType(item.status),"items":_vm.quickStatusSelections},on:{"click":function($event){return _vm.updateUserCommentAction(Object.assign({}, item, $event))}}})]}},{key:"toxicity",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toxicityLabel(item.toxicity))+" ")]}},{key:"score",fn:function(ref){
                        var item = ref.item;
return [(item.toxicity)?_c('CellScore',{attrs:{"label":item.toxicity.highestScore.score,"items":_vm.getCellScoreItems(item.toxicity.labels, _vm.$options.commentsReportType)}}):_c('CellContext',{attrs:{"label":item.confidence}})]}},{key:"reports",fn:function(ref){
                        var item = ref.item;
return [_c('CellScore',{attrs:{"label":item.reportTotal,"items":_vm.getCellScoreItems(item.reports.reportType)}})]}},{key:"details",fn:function(ref){
                        var item = ref.item;
return [_c('UserCommentsDetails',{attrs:{"item":item},on:{"change":_vm.onDetailsChange,"update":_vm.updateUserComment}})]}}])}),_c('Paging',{attrs:{"total":_vm.comments.total,"size":_vm.comments.size,"page":_vm.comments.page},on:{"pageSelected":_vm.selectPage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }